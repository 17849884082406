
.activity_container {
  margin-right: auto; /* 1 */
  margin-left:  auto; /* 1 */

  max-width: 70vw;
  text-size-adjust: 100%;

  /* padding-right: 2vw; 
  padding-left:  2vw; */

}

.preimage { 
  position: relative;
  
  /* min-height: 300px; */
  /* height: 50%; */
  /* max-height: 500px;   */
  /* width: 100%; */

  /* minHeight: 300; */
  /* min-height: '50%'; */
  /* min-height: 10em; */
  
  margin-bottom: 1rem;

  /* max-height: calc(); */
  min-height: 35vw;
  height: 60vh;
  width: 100%;

  object-fit: 'cover';
  background-color: #FD8E43;
  
}

.activity_title {
  font-size: 1.3rem;
  font-weight: 900;
}

.activity_text {
  font-size: 1rem;
}

@media (max-width: 768px) {

  .activity_container {
    max-width: 95vw;
    /* min-height: 70vw; */
  }

  .activity_text {
    font-size: 1.1rem;
  }

  .preimage {
    max-height: 30vh;
  }
    
}