.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  /* font-size: 1.5em; */
  font-size: 1.2em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


/* .main {
  background-color: "#333";
  height: "100vh" 
} */

.rest {
  padding-top: 56px
}

.centered_container {
  margin-right: auto; /* 1 */
  margin-left:  auto; /* 1 */
  max-width: 70vw;
}


@media (max-width: 768px) {

  .centered_container {
    max-width: 95vw;
    
  }
}