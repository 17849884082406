
.activity_container {
  margin-right: auto; /* 1 */
  margin-left:  auto; /* 1 */

  max-width: 70vw;
  text-size-adjust: 100%;
  padding-bottom: 50px;

  /* padding-right: 2vw; 
  padding-left:  2vw; */
  line-height: 1.25rem;
  /* font-size: .872em; */
  font-size: 1em;

}

.preimage { 
  position: relative;
  
  /* min-height: 300px; */
  /* height: 50%; */
  /* max-height: 500px;   */
  /* width: 100%; */

  /* minHeight: 300; */
  /* min-height: '50%'; */
  /* min-height: 10em; */
  
  margin-bottom: 1rem;

  /* max-height: calc(); */
  min-height: 35vw;
  /* height: 60vh; */
  height: 100vh;
  width: 100%;

  object-fit: 'cover';
  background-color: #FD8E43;
  
}

.activity_title {
  /* font-size: 1.5rem; */
  /* font-size: 1rem; */
  line-height: normal;
  /* font-weight: 900; */
  font-weight: bold;
  
}

/* .activity_text { */
  /* font-size: 1rem; */
/* } */

@media (max-width: 768px) {

  .activity_container {
    max-width: 95vw;
    /* min-height: 70vw; */
  }

  /* .activity_text {
    font-size: 1.1rem;
  } */

  .preimage {
    max-height: 30vh;
  }
    
}