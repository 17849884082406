/* @import url('https://fonts.googleapis.com/css?family=Carter+One'); */
/* @import url('https://fonts.googleapis.com/css?family=Oregano'); */
/* @import url('https://fonts.googleapis.com/css?family=Lobster'); */
/* @import url('https://fonts.googleapis.com/css?family=Lato'); */

.Landing {
  /* font-family: 'Carter One', cursive; */
  /* font-family: 'Oregano', cursive; */
  /* font-family: 'Lobster', cursive; */
  /* font-family: 'Lato', sans-serif; */
  font-size: 1em
}

.myLandingContainer {
  width: "100%";
  padding: 1.2em;
  padding-top: 2em;
  padding-bottom: 2em;
}

/* p {
  font-size: 1.5em 
} */