
$theme-colors: (
  // 'primary': #7256ad,
  // 'primary': #FD8E43,
  'primary': #A80834,
  'red-bnb': #A80834,
  'orange-bnb': #FD8E43, 
);

@import "bootstrap.scss"

