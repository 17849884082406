@import url('https://fonts.googleapis.com/css?family=Lato');

html, body, #app, #root > div {
/* html, body  { */
  /* height: calc(100vh - 60px); */
  font-family: 'Lato', sans-serif;
  /* background-color: #333; */
  /* background-color: #000; */
  /* background-color: #fff; */
  background-color: #DCDCDC; 
  /* background-color: #F0F0F0;  */
  /* background-color: #F5F5F5; */
  /* color: #fff; */
  
}

/* #root {
  margin-top: 50px;
} */

/* #root {
  height: 100vh;
  background-color: #333 
} */


/* body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  background-color: "#333"
} */

/* .bg-dark {
  background-color: blue;
  color: blue;
} */


