.AgendaCalendario {
  color: black;
  
}

.todayClass {
  /* border: 6px solid red; */
  /* background-color: red */
  /* background-color:#A80834 */
  background-color:#FD8E43
}

.grey-title {
  /* color: grey */
  color: #696969
}

.title {
  font-weight: 900;
  font-size: 1.025rem;
}