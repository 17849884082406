.agendaCoverStyle {
  width: 300px;
  height: 300px;
}

@media (max-width: 500px) {
  .agendaCoverStyle {
    width: 200px;
    height: 200px;
  }
  
}

.agendaLink2 {

  text-decoration: none;
  color: black;

  &:link {
    text-decoration: none;
  }

  &:visited {
    color: red
  }
  
  &:hover {
    cursor: pointer;
    color: gray
  }

  &:active{
    color: red
  }
  
}


