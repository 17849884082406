/* import imgCariri from '../assets/images/patativa300.jpg'; */
/* background-color: red; */

@import url('https://fonts.googleapis.com/css?family=Carter+One');


.hoverRed {
  background: url('../assets/images/patativa300.jpg') center;
  width: 250px;
  height: 250px;
  background-blend-mode: multiply;
  
}

.hoverRed:hover {
  background-color: red;
  transition: 200ms ease-in-out
}

.txt-ccbnb {
  font-size: 2em;
  text-align: center;
  font-family: 'Carter One', cursive;
}

.container-img-ccbnb {
  /* color: black; */
  color: #A80834;
  background-color: white;
  padding: 1px;
  width: 300px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}

.img-ccbnb {
  width: 7.5em;
  border-radius: 3px 0 0 3px 
}