.destaque_title {
  padding-top: 15px;
  margin-bottom: 5px;
}

.containerAreaDestaques {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 20px;
}

.containerDescription {
  padding: 50px;
  /* padding-bottom: 100px; */
}