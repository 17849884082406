.lazy-load-image-background {
  min-height: 150px
}


.destaque {

  background-color: white; 
  /* background-color: red; */
  /* border: 10px #696969; */
  
  /* margin: 10px;
  margin-left: 0px; */

  margin: 10px 10px 10px 0;
  /* margin-left: 0px; */

  width: 15rem;
  min-height: 15rem;
  
  text-decoration: none;
  cursor: pointer;

  /* &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none; 
    transform: scale(1.5)
  } */

  /* :focus, :hover {
    transform: scale(1.5);
  } */
  
}

.grey-title {
  /* color: grey */
  color: #696969
}

.title {
  font-weight: 900;
  font-size: 1.025rem;
}

.containerTitle {
  padding-bottom: 10px;
}